import indexState from "../../store/index.js";

function dealerName(dealer) {
  if (dealer) {
    return dealer.first_name + " " + dealer.last_name;
  }
  return "—";
}

function dealerNameFromUrl(dealerUrl) {
  if (dealerUrl) {
    let filteredDealers = indexState.getters["dealershipStore/dealers"].filter(dealer => {
      return dealer.url == dealerUrl;
    });
    if (filteredDealers[0]) {
      return dealerName(filteredDealers[0]);
    }
  }

  return "";
}

export { dealerName, dealerNameFromUrl };
