function getTopValue(offer, forDealer) {
  if (forDealer) {
    if (offer.adjusted_value) {
      return offer.adjusted_value;
    }
    return offer.price;
  } else {
    return offer.price;
  }
}

export { getTopValue };
